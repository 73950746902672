import { useState, useEffect, useRef, createRef, ReactElement } from 'react'
import { useRouter } from 'next/router'
import ValidatorWrapper, { ValidatorField } from '@coxy/react-validator'
import cn from 'classnames'

import validatorRules from 'libs/validator-rules'
import * as ga from 'libs/ga'
import { toFixed } from 'utils/formatters'

import Notice from 'components/ui/notice'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getCurrencies, setActiveCodeFrom } from 'store/reducers/currencies'
import { createLoan, estimateLoanAmount } from 'store/fetchers/loans'
import { ICurrency } from 'store/fetchers/currencies'
import { EExchangeDirections, IExchangeData } from 'store/fetchers/loans/enum'
import { fetchSettings, getSettings } from 'store/reducers/settings'

import { ICoinPageCurrency } from 'config/coin-pages-list'

import LtvOption from 'components/ui/calculator/components/ltv-option'

import ButtonOrange from '../button/_color/_orange'

import { selectedUser } from '../../../store/reducers/users'

import TermsBlock from './components/terms-block'
import InputCalculator from './components/input-calculator'

import corner from './icons/corner.svg'

import styles from './styles.module.scss'
// import ProcessingRate from '../processing-rate'

const DEFAULT_FROM = '0.1'

export default function Calculator({ pageCurrency }: { pageCurrency?: ICoinPageCurrency }): ReactElement {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const user = useAppSelector(selectedUser)

  const currencies = useAppSelector(getCurrencies)
  const settings = useAppSelector(getSettings)

  const timer = useRef<any>() // cannot set NodeJS.Timeout
  const validator = createRef<ValidatorWrapper>()
  const requestedPairRef = useRef<[ICurrency, ICurrency]>()
  const isMounted = useRef(true)
  const skipToEstimate = useRef(false)

  const [from, setFrom] = useState<ICurrency>(null)
  const [to, setTo] = useState<ICurrency>(null)
  const [amountTo, setAmountTo] = useState('')
  const [exchangeData, setExchangeData] = useState<IExchangeData>(null)
  const [isValidAmounts, setIsValidAmounts] = useState<boolean>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingFrom, setLoadingFrom] = useState(false)
  const [isLoadingTo, setLoadingTo] = useState(true)
  const [listDepositCurrencies, setListDepositCurrencies] = useState<ICurrency[]>([])
  const [listLoanCurrencies, setListLoanCurrencies] = useState<ICurrency[]>([])
  const [estimateError, setEstimateError] = useState('')
  const [ltvPercent, setLtvPercent] = useState('')
  const [renderedLtvOptions, setRenderedLtvOptions] = useState<ReactElement[]>([])

  const [_amountFrom, _setAmountFrom] = useState(DEFAULT_FROM)
  const amountFrom = useRef(_amountFrom)

  const setAmountFrom = (value: string) => {
    _setAmountFrom(value)
    amountFrom.current = value
  }

  const handleSetFrom = (from: ICurrency) => {
    setFrom(from)
  }

  const handleSetTo = (to: ICurrency) => {
    setTo(to)
  }

  const handleChangeSide = () => () => {
    router.push('/earn')
  }

  useEffect(() => {
    dispatch(fetchSettings())
    clearTimeout(timer.current)

    return () => {
      clearTimeout(timer.current)
      isMounted.current = false
    }
  }, [])

  // for visible forms
  useEffect(() => {
    const { isValid } = validator.current.validate()
    if (isValid !== isValidAmounts) setIsValidAmounts(isValid)
  }, [amountFrom, amountTo])

  const filterCurrenciesList = (exclude: ICurrency) => (item: ICurrency) => {
    return item.code !== exclude?.code && item.network !== exclude?.network
  }

  useEffect(() => {
    const parseCurrencies = async () => {
      if (currencies?.length === 0) {
        return
      }

      skipToEstimate.current = true

      let depositCurrencies: ICurrency[] = []
      let loanCurrencies: ICurrency[] = []
      let foundPageCurrency: ICurrency = null

      for (const currency of currencies) {
        if (currency.is_loan_deposit_enabled) {
          depositCurrencies.push(currency)
        }
        if (currency.is_loan_receive_enabled) {
          loanCurrencies.push(currency)
        }
        if (pageCurrency && !foundPageCurrency) {
          if (
            currency.code.toLowerCase() === pageCurrency.code.toLowerCase() &&
            currency.network.toLowerCase() === pageCurrency.network.toLowerCase()
          ) {
            foundPageCurrency = currency
          }
        }
      }

      depositCurrencies = depositCurrencies.sort((a, b) => a.loan_deposit_priority - b.loan_deposit_priority)

      loanCurrencies = loanCurrencies.sort((a, b) => a.loan_receive_priority - b.loan_receive_priority)

      let newFrom: ICurrency
      let newTo: ICurrency
      if (foundPageCurrency) {
        if (foundPageCurrency.is_loan_deposit_enabled) {
          newFrom = foundPageCurrency
        } else if (foundPageCurrency.is_loan_receive_enabled) {
          newTo = foundPageCurrency
          newFrom = depositCurrencies.find(filterCurrenciesList(newTo))
        }
      } else {
        newFrom = depositCurrencies[0]
      }

      if (!newTo) {
        newTo = loanCurrencies.find(filterCurrenciesList(newFrom))
      }

      if (isMounted.current) {
        setFrom(newFrom)
        setTo(newTo)

        setListLoanCurrencies(loanCurrencies)
        setListDepositCurrencies(
          depositCurrencies.filter((cur) => cur.loan_deposit_max_amount === null || +cur.loan_deposit_max_amount !== 0),
        )
      }
    }

    void parseCurrencies()
  }, [pageCurrency, currencies])

  const estimate = (
    amount: string,
    direction: EExchangeDirections,
    toCurrency: ICurrency,
    fromCurrency: ICurrency,
  ) => async () => {
    if (!isMounted.current) return

    requestedPairRef.current = [fromCurrency, toCurrency]

    const { response } = await estimateLoanAmount({
      from_code: fromCurrency.code,
      from_network: fromCurrency.network,
      to_code: toCurrency.code,
      to_network: toCurrency.network,
      amount: amount,
      exchange: direction,
      ltv_percent: ltvPercent,
    })

    if (!isMounted.current) return

    if ('message' in response) {
      setEstimateError(response.message)
      if (direction === EExchangeDirections.direct) {
        setAmountTo('')
      } else {
        setAmountFrom('')
      }
    } else {
      setEstimateError('')
      if (direction === EExchangeDirections.direct) {
        setAmountTo(toFixed(response.amount_to, toCurrency?.decimal_places))
      } else {
        setAmountFrom(toFixed(response.amount_from, fromCurrency?.decimal_places))
      }

      const [fromRef, toRef] = requestedPairRef.current
      if (
        fromRef.code === fromCurrency.code &&
        fromRef.network === fromCurrency.network &&
        toRef.code === toCurrency.code &&
        toRef.network === toCurrency.network
      ) {
        setExchangeData(response)
      }
    }

    if (direction === EExchangeDirections.direct) {
      setLoadingTo(false)
    } else {
      setLoadingFrom(false)
    }
  }

  const prepareEstimate = (amount: string, direction: EExchangeDirections, _to?: ICurrency, _from?: ICurrency) => {
    clearTimeout(timer.current)

    const toCurrency = _to || to
    const fromCurrency = _from || from
    if (!ltvPercent || !toCurrency || !fromCurrency || Number.isNaN(parseFloat(amount))) {
      return
    }

    if (direction === EExchangeDirections.direct) {
      setLoadingTo(true)
    } else {
      setLoadingFrom(true)
    }

    timer.current = setTimeout(estimate(amount, direction, toCurrency, fromCurrency), 200)
  }

  useEffect(() => {
    if (!to) {
      return
    }

    if (to.code === from?.code && to.network === from?.network) {
      setFrom(listDepositCurrencies.find(filterCurrenciesList(to)))
      return
    } else if (from?.is_stable && to?.is_stable) {
      setFrom(listDepositCurrencies.find((el) => el.is_stable))
      return
    }

    if (skipToEstimate.current) {
      skipToEstimate.current = false
    } else {
      prepareEstimate(amountFrom.current, EExchangeDirections.direct)
    }
  }, [to])

  useEffect(() => {
    if (!from) {
      return
    }

    let newTo = null
    if (from.code === to?.code && from.network === to?.network) {
      skipToEstimate.current = true
      newTo = listLoanCurrencies.find(filterCurrenciesList(from))
      setTo(newTo)
    } else if (from.is_stable && to?.is_stable) {
      skipToEstimate.current = true
      newTo = listLoanCurrencies.find((el) => el.is_stable)
      setTo(newTo)
    }

    const defaultAmount = from.loan_deposit_default_amount || '10000'
    setAmountFrom(defaultAmount)
    dispatch(setActiveCodeFrom(from.code))

    prepareEstimate(defaultAmount, EExchangeDirections.direct, newTo, from)
  }, [from])

  const handleChangeLtvPercent = (value: string) => () => {
    setLtvPercent(value)
  }

  useEffect(() => {
    setLoadingTo(true)

    let newLtvOptions

    if (from?.ltv_options) {
      newLtvOptions = from.ltv_options.map((value, i) => (
        <LtvOption
          ltvPercent={ltvPercent}
          value={value.trim()}
          key={i}
          handleChangeLtvPercent={handleChangeLtvPercent}
        />
      ))
    } else {
      newLtvOptions = settings.ltvOptions.map((value, i) => (
        <LtvOption ltvPercent={ltvPercent} value={value} key={i} handleChangeLtvPercent={handleChangeLtvPercent} />
      ))
    }

    setRenderedLtvOptions(newLtvOptions)
    prepareEstimate(amountFrom.current, EExchangeDirections.direct)
  }, [from, ltvPercent])

  useEffect(() => {
    if (!from?.ltv_options?.at) {
      setLtvPercent(settings.ltvDefaultOption)
      return
    }
    setLtvPercent(from?.ltv_options?.at(-1) ?? settings.ltvDefaultOption)
  }, [from, currencies, settings.ltvDefaultOption])

  async function handleCreateLoan() {
    const { isValid } = validator.current.validate()

    if (!isValid) {
      return
    }

    setIsLoading(true)

    const loan = await createLoan({
      user_id: user?.id,
      deposit: {
        currency_code: from.code,
        currency_network: from.network,
        expected_amount: exchangeData.amount_from,
      },
      loan: {
        currency_code: to.code,
        currency_network: to.network,
      },
      ltv_percent: ltvPercent,
    })

    if (!loan || !loan.loan_id) {
      setIsLoading(false)
      return
    }

    ga.event({
      action: 'Loan_landing_step_1_get',
    })

    await router.push({
      pathname: '/loan/confirm',
      query: {
        from_code: from.code,
        from_network: from.network,
        to_code: to.code,
        to_network: to.network,
        amount: exchangeData.amount_from,
        loan_id: loan.loan_id,
        ltv_percent: ltvPercent,
        isExternal: 'true',
      },
    })
  }

  const handleSetAmountFrom = (amount: string) => {
    clearTimeout(timer.current)
    setAmountFrom(amount)
    prepareEstimate(amount, EExchangeDirections.direct)
  }

  const handleSetAmountTo = (amount: string) => {
    clearTimeout(timer.current)
    setAmountTo(amount)
    prepareEstimate(amount, EExchangeDirections.reverse)
  }

  const borrowTitleCodes = ['btc', 'floki', 'jasmy', 'volt', 'bone', 'quack', 'babydoge']
  const calculatorTitle =
    borrowTitleCodes.includes(pageCurrency?.code) || pageCurrency?.borrowTitle
      ? `Borrow Against ${pageCurrency?.customFields?.calculatorHeading || pageCurrency.name}`
      : `${pageCurrency?.customFields?.calculatorHeading || pageCurrency?.name} Loan Calculator`

  return (
    <ValidatorWrapper ref={validator}>
      {!pageCurrency && (
        <>
          <div className={styles.tabs}>
            <div className={cn(styles.tab, styles.activeTab)}>
              <p className={styles.tabName}>Loans</p>
            </div>

            <div className={styles.tab} onClick={handleChangeSide()}>
              <p className={styles.tabName}>Savings</p>
              <img src={corner} alt="corner_image" className={styles.corner} width={34} height={44} />
            </div>
          </div>
        </>
      )}
      <div className={styles.calculator}>
        <div className={cn(styles.selectionBlock, { [styles.selectionBlockCoin]: pageCurrency !== undefined })}>
          <div className={styles.noticeWrapper}>
            {!pageCurrency && (
              <>
                <h2 className={styles.title}>Borrow now & hold for later</h2>
              </>
            )}
            {pageCurrency && <h2 className={styles.title}>{calculatorTitle}</h2>}
          </div>
          <div className={styles.inputsBlock}>
            <ValidatorField value={amountFrom.current} rules={validatorRules.depositAmount(from)}>
              {({ isValid, message }) => (
                <InputCalculator
                  className={styles.inputFrom}
                  onEnter={handleCreateLoan}
                  coin={from}
                  isLoading={isLoadingFrom}
                  onChangeAmount={handleSetAmountFrom}
                  onChangeCoin={handleSetFrom}
                  value={_amountFrom}
                  label="Send collateral"
                  list={listDepositCurrencies}
                  inputWrapperClassName={styles.wrapper}
                  listFullWidth
                >
                  {(estimateError ||
                    (!isValid && !isLoadingFrom && (amountTo !== '—' || amountFrom.current !== '—'))) && (
                    <div className={styles.errorAmount}>{estimateError || message}</div>
                  )}
                </InputCalculator>
              )}
            </ValidatorField>

            {/* <ProcessingRate coin={from} confirmPage={false} /> */}

            <div className={styles.ltvWrapper}>
              <div className={styles.ltv}>
                <span className={styles.ltvTitle}>LTV</span>
                <div className={styles.ltvNotice}>
                  <Notice alt={'ltv-notice'}>
                    The loan-to-value ratio is the related difference between the loan amount and the current market
                    value of the collateral. The higher the LTV, the more loan funds you will receive, but at the same
                    time the margin call will be higher.
                  </Notice>
                </div>
                <div className={styles.ltvOptions}>{renderedLtvOptions}</div>
              </div>
            </div>

            <ValidatorField value={amountTo} rules={validatorRules.loanAmount(to)}>
              {({ isValid, message }) => (
                <InputCalculator
                  className={styles.inputTo}
                  onEnter={handleCreateLoan}
                  coin={to}
                  isLoading={isLoadingTo}
                  onChangeAmount={handleSetAmountTo}
                  onChangeCoin={handleSetTo}
                  value={amountTo}
                  label="Get funds"
                  list={listLoanCurrencies}
                  inputWrapperClassName={styles.wrapper}
                  isStableFirst={true}
                  listFullWidth
                >
                  {!estimateError && !isValid && !isLoadingTo && (amountTo !== '—' || amountFrom.current !== '—') && (
                    <div className={styles.errorAmount}>{message}</div>
                  )}
                </InputCalculator>
              )}
            </ValidatorField>
          </div>
          <TermsBlock exchange={exchangeData} to={to} from={from} />
        </div>
      </div>
      <ButtonOrange
        isLoading={isLoading}
        disabled={!isValidAmounts}
        onClick={handleCreateLoan}
        className={styles.buttonGetLoan}
      >
        Get Loan
      </ButtonOrange>
    </ValidatorWrapper>
  )
}
