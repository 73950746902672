import cn from 'classnames'

import styles from './styles.module.scss'

const LtvOption = ({ ltvPercent, value, handleChangeLtvPercent }) => {
  return (
    <div
      className={cn(styles.ltvOption, { [styles.ltvOptionSelected]: ltvPercent === value })}
      onClick={handleChangeLtvPercent(value)}
    >
      {Number(value) * 100}%
    </div>
  )
}

export default LtvOption
